/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  Outlet,
  Navigate,
  redirect,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import NavWithLogin from '../../components/Compute/Navbars/nav-with-login';
import ComputeBilling from '../../pages/ComputeNew/Compute/NewComputePricing/ComputePricing';
import ComputeDashboard from '../../pages/ComputeNew/Compute/Dashboard';
import Members from '../../pages/ComputeNew/Global/Members';
import TemplateNavbar from '../../components/Compute/Navbars/template-navbar';
import InstanceDetails from '../../pages/ComputeNew/Compute/InstanceDetails';
import ServiceMainView from '../../pages/ComputeNew/Compute/ServiceMainView/service-main-view';
import config from '../../config';
import CurrentPlan from '../../pages/ComputeNew/Global/PlanCurrent';
import PlanUsage from '../../pages/ComputeNew/Global/PlanUsage';
import Billing from '../../pages/ComputeNew/Global/Billing';
import ClusterBilling from '../../pages/ComputeNew/Compute/ClusterBilling';
import PaymentHistory from '../../pages/ComputeNew/Global/PaymentHistory';
import ClusterInstanceBilling from '../../pages/ComputeNew/Compute/ClusterInstanceBilling';
import PlanInvoiceSettings from '../../pages/ComputeNew/Global/PlanInvoiceSettings';
import SpheronCredit from '../../pages/ComputeNew/Global/SpheronCredit';
import OrganisationGeneralSettings from '../../pages/ComputeNew/Global/OrganisationGeneralSettings';
import OrganisationSettingsEmailPreferences from '../../pages/ComputeNew/Global/OrganisationSettingsEmailPreferences';
import InstanceOverview from '../../pages/ComputeNew/Compute/InstanceOverview';
import InstanceLogsMainView from '../../pages/ComputeNew/Compute/InstanceLogsMainView';
import InstanceActivityCard from '../../components/Compute/Cards/instance-activity-card';
import InstanceDomainMainView from '../../pages/ComputeNew/Compute/InstanceDomainMainView';
import InstanceSettingsCard from '../../components/Compute/Cards/instance-settings-card';
import ProviderSignup from '../../pages/ComputeNew/Global/ProviderSignup/provider-signup';
import ProviderLogin from '../../pages/ComputeNew/Global/ProviderLogin';
import ProviderCallback from '../../pages/ComputeNew/Global/ProviderCallback';
import ProviderApp from '../../pages/ComputeNew/Global/ProviderApp';
import ProviderAppCallback from '../../pages/ComputeNew/Global/ProviderAppCallback';
import LoginConnection from '../../pages/ComputeNew/Global/LoginConnection';
import LoginConnectionCallback from '../../pages/ComputeNew/Global/LoginConnectionCallback';
import CliLogin from '../../pages/ComputeNew/Global/CliLogin';
import NavCliLogin from '../../components/Compute/Navbars/nav-cli-login';
import CryptoPaymentCallback from '../../pages/ComputeNew/Global/CryptoPaymentCallback';
// import SignUp from '../../pages/ComputeNew/Global/SignUp';
import PostLogin from '../../pages/ComputeNew/Global/PostLogin';
import ChooseApp from '../../pages/ComputeNew/Global/ChooseApp';
// import Login from '../../pages/ComputeNew/Global/Login';
import OnboardingComponent from '../../pages/ComputeNew/Global/OnboardingComponent';
import NavAfterLogin from '../../components/Compute/Navbars/nav-after-login';
import InviteCallback from '../../pages/ComputeNew/Global/InviteCallback';
import UserSettings from '../../pages/ComputeNew/Global/UserSettings';
import UserSettingConnections from '../../pages/ComputeNew/Global/UserSettingsConnections';
import UserSettingTokens from '../../pages/ComputeNew/Global/UserSettingsTokens';
import UserSettingReferrals from '../../pages/ComputeNew/Global/UserSettingsReferrals';
import Modals from '../../components/Compute/Modals/modals';
import DashboardMainView from '../../pages/ComputeNew/Global/DashboardMainView';
import UserGeneralSettings from '../../pages/ComputeNew/Global/UserSettingsGeneral';
import ServiceOverview from '../../pages/ComputeNew/Compute/ServiceOverview';
import InstanceMonitoring from '../../pages/ComputeNew/Compute/InstanceMonitoring';
import ServiceShell from '../../pages/ComputeNew/Compute/ServiceShell';
import InstanceActivityDetails from '../../pages/ComputeNew/Compute/ActivityDetails';
import PageNotFound from '../../pages/ComputeNew/Global/PageNotFound/page-not-found';
import ErrorFallback from '../../pages/ComputeNew/Global/ErrorFallback';
import ChooseSpheronApp from '../../pages/ComputeNew/Global/ChooseSpheronApp';
import AuthLogin from '../../pages/ComputeNew/Global/AuthPages/Login';
import AuthSignup from '../../pages/ComputeNew/Global/AuthPages/Signup';
import AuthLogout from '../../pages/ComputeNew/Global/AuthPages/Logout';
// import DashboardMainView from '../pages/ComputeNew/Global/DashboardMainView';

const CreateMasterOrganisation = lazy(
  () => import('../../pages/ComputeNew/Global/CreateMasterOrganisation')
);
const TemplateInfo = lazy(
  () => import('../../pages/ComputeNew/Compute/TemplateInfo')
);
const OrganizationSettings = lazy(
  () => import('../../pages/ComputeNew/Global/OrganizationSettings')
);

const hasJwt = localStorage.getItem('jwt-token');
const guardLoader = (children: JSX.Element) => {
  if (!hasJwt) {
    // localStorage.setItem('referrer', 'true');
    // localStorage.setItem('referredFrom', window.location.pathname);
    return <Navigate to="/login" />;
  }
  // eslint-disable-next-line consistent-return
  return children;
};

const templateLoader = () => {
  if (hasJwt) {
    return <Outlet />;
  }
  return (
    <TemplateNavbar>
      <Outlet />
    </TemplateNavbar>
  );
};

const computeLoader = (params: any) => {
  if (
    config.compute.DISABLE_NEW_INSTANCE &&
    params.computeProjectId === 'new-instance'
  ) {
    return redirect(`/${params.orgUsername}/dashboard`);
  }
  return null;
};

const inviteRedirectLoader = () => {
  const inviteRef = localStorage.getItem('inviteRef');
  const orgName = localStorage.getItem('orgName');
  if (inviteRef) {
    return redirect(
      `/invite/callback?ref=${encodeURIComponent(
        inviteRef
      )}&orgName=${encodeURIComponent(orgName || '')}`
    );
  }
  return null;
};

const switchCliNavbar = (children: JSX.Element) => {
  if (hasJwt) {
    return <NavAfterLogin>{children}</NavAfterLogin>;
  }
  return <NavCliLogin>{children}</NavCliLogin>;
};

const withUnguardedRoute = (children: JSX.Element) => {
  if (!hasJwt) {
    return <>{children}</>;
  }
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const redirectUrl = params.get('redirectUrl');
  if (redirectUrl) {
    return <Navigate to={`/post-login?redirectUrl=${redirectUrl}`} />;
  }
  return <Navigate to="/post-login" />;
};

const computeRouter = createBrowserRouter([
  {
    element: (
      <>
        <Modals />
        <Sentry.ErrorBoundary
          fallback={ErrorFallback}
          onError={(error: any, info: any) => {
            // eslint-disable-next-line no-console
            console.error('error ', error);
            // eslint-disable-next-line no-console
            console.info('info ', info);
          }}
        >
          <Outlet />
        </Sentry.ErrorBoundary>
      </>
    ),
    children: [
      {
        path: '/',
        element: <>{withUnguardedRoute(<Navigate to="/login" />)}</>,
      },
      // {
      //   path: 'signup',
      //   element: withUnguardedRoute(<SignUp />),
      // },
      // {
      //   path: 'login',
      //   element: withUnguardedRoute(<Login />),
      // },
      {
        path: 'login',
        element: withUnguardedRoute(<AuthLogin />),
      },
      {
        path: 'signup',
        element: withUnguardedRoute(<AuthSignup />),
      },
      {
        path: 'logout',
        element: guardLoader(<AuthLogout />),
      },
      {
        path: 'post-login',
        element: guardLoader(<PostLogin />),
      },
      // {
      //   path: 'chooseapp',
      //   element: guardLoader(<ChooseApp />),
      // },
      // {
      //   path: 'onboarding',
      //   element: guardLoader(
      //     <Suspense
      //       fallback={
      //         <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //       }
      //     >
      //       <NavAfterLogin>
      //         <OnboardingComponent />
      //       </NavAfterLogin>
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: 'create-new-organisation',
      //   element: guardLoader(
      //     <Suspense
      //       fallback={
      //         <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //       }
      //     >
      //       <NavAfterLogin>
      //         <CreateMasterOrganisation />
      //       </NavAfterLogin>
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: 'invite/callback',
      //   element: (
      //     <NavWithLogin>
      //       <InviteCallback />
      //     </NavWithLogin>
      //   ),
      // },
      {
        path: 'user/settings',
        element: guardLoader(
          <NavAfterLogin>
            <UserSettings />
          </NavAfterLogin>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/user/settings/general" />,
          },
          {
            path: 'general',
            element: <UserGeneralSettings />,
          },
          {
            path: 'login-connections',
            element: <UserSettingConnections />,
          },
          {
            path: 'api-tokens',
            element: <UserSettingTokens />,
          },
          {
            path: 'referrals',
            element: <UserSettingReferrals />,
          },
        ],
      },
      {
        path: 'signup/:provider',
        element: <ProviderSignup />,
      },
      {
        path: 'login/:provider',
        element: <ProviderLogin />,
      },
      {
        path: 'callback/:provider',
        element: <ProviderCallback />,
      },
      {
        path: ':provider/auth/:id',
        element: <ProviderApp />,
      },
      {
        path: ':provider/callback/auth',
        element: <ProviderAppCallback />,
      },
      {
        path: ':provider/new-login-connection',
        element: <LoginConnection />,
      },
      {
        path: 'connection-callback',
        element: <LoginConnectionCallback />,
      },
      {
        path: 'notifications/cli-login',
        element: switchCliNavbar(<CliLogin />),
      },
      // {
      //   path: 'crypto-payment-callback',
      //   element: <CryptoPaymentCallback />,
      // },
      {
        path: 'choose-spheron-app',
        element: guardLoader(
          <NavAfterLogin>
            <ChooseSpheronApp />
          </NavAfterLogin>
        ),
      },
      // {
      //   path: ':orgUsername',
      //   element: guardLoader(
      //     <NavWithLogin>
      //       <>
      //         <Outlet />
      //       </>
      //     </NavWithLogin>
      //   ),
      //   children: [
      //     {
      //       index: true,
      //       loader: ({ params }) => {
      //         return redirect(`/${params.orgUsername}/dashboard`);
      //       },
      //     },
      //     {
      //       path: 'dashboard',
      //       loader: () => inviteRedirectLoader(),
      //       element: <DashboardMainView />,
      //       children: [
      //         {
      //           index: true,
      //           element: <ComputeDashboard />,
      //         },
      //       ],
      //     },
      //     {
      //       path: 'members',
      //       element: <Members />,
      //     },
      //     {
      //       path: 'settings',

      //       element: (
      //         <Suspense
      //           fallback={
      //             <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //           }
      //         >
      //           <OrganizationSettings />
      //         </Suspense>
      //       ),
      //       children: [
      //         {
      //           index: true,
      //           loader: ({ params }) => {
      //             return redirect(`/${params.orgUsername}/settings/general`);
      //           },
      //         },
      //         {
      //           path: 'general',
      //           element: <OrganisationGeneralSettings />,
      //         },
      //         {
      //           path: 'email-preferences',
      //           element: <OrganisationSettingsEmailPreferences />,
      //         },
      //       ],
      //     },
      //     {
      //       path: 'billing',
      //       element: <DashboardMainView />,
      //       children: [
      //         {
      //           element: <Billing />,
      //           children: [
      //             {
      //               index: true,
      //               loader: ({ params }) => {
      //                 return redirect(`/${params.orgUsername}/billing/plan`);
      //               },
      //             },
      //             { path: 'plan', element: <CurrentPlan /> },
      //             { path: 'plan-usage', element: <PlanUsage /> },
      //             {
      //               path: 'compute-usage',
      //               element: <ClusterBilling />,
      //             },
      //             {
      //               path: 'compute-usage/:clusterId',
      //               element: <ClusterInstanceBilling />,
      //             },
      //             { path: 'payment-history', element: <PaymentHistory /> },
      //             {
      //               path: 'invoice-settings',
      //               element: <PlanInvoiceSettings />,
      //             },
      //             { path: 'spheron-credit', element: <SpheronCredit /> },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       path: 'settings',
      //       element: (
      //         <Suspense
      //           fallback={
      //             <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //           }
      //         >
      //           <OrganizationSettings />
      //         </Suspense>
      //       ),
      //     },
      //     {
      //       path: ':computeProjectId/new-instance',
      //       loader: ({ params }) => computeLoader(params),
      //       element: <ComputeBilling />,
      //     },
      //     {
      //       path: 'marketplace',
      //       element: templateLoader(),
      //       children: [
      //         {
      //           index: true,
      //           element: (
      //             <Suspense
      //               fallback={
      //                 <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //               }
      //             >
      //               <TemplateInfo />
      //             </Suspense>
      //           ),
      //         },
      //       ],
      //     },
      //     {
      //       path: 'create-new-organisation',
      //       element: (
      //         <Suspense
      //           fallback={
      //             <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //           }
      //         >
      //           <CreateMasterOrganisation />
      //         </Suspense>
      //       ),
      //     },
      //     {
      //       path: ':projectId/:instanceId',
      //       element: <InstanceDetails />,
      //       children: [
      //         {
      //           index: true,
      //           element: <InstanceOverview />,
      //         },
      //         {
      //           path: 'overview',
      //           element: <InstanceOverview />,
      //         },
      //         {
      //           path: 'logs',
      //           element: <InstanceLogsMainView />,
      //           children: [
      //             { path: ':logType', element: <InstanceLogsMainView /> },
      //           ],
      //         },
      //         {
      //           path: 'activity',
      //           element: <InstanceActivityCard />,
      //         },
      //         { path: 'domains', element: <InstanceDomainMainView /> },
      //         {
      //           path: 'settings',
      //           element: <InstanceSettingsCard />,
      //         },
      //         {
      //           path: ':deploymentId/:serviceName/activity-details',
      //           element: <InstanceActivityDetails />,
      //         },
      //       ],
      //     },
      //     {
      //       path: ':projectId/:instanceId/service/:serviceId',
      //       element: <ServiceMainView />,
      //       children: [
      //         {
      //           index: true,
      //           path: 'overview',
      //           element: <ServiceOverview />,
      //         },
      //         {
      //           path: 'metrics',
      //           element: <InstanceMonitoring showHealthCheck={false} />,
      //         },
      //         {
      //           path: 'shell',
      //           element: <ServiceShell />,
      //         },
      //       ],
      //     },
      //     {
      //       path: 'project/:projectId',
      //       element: <DashboardMainView />,
      //       children: [
      //         {
      //           index: true,
      //           element: <ComputeDashboard />,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: 'marketplace',
      //   element: templateLoader(),
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <Suspense
      //           fallback={
      //             <div className="h-screen w-full bg-base-bg dark:bg-dark-base-bg" />
      //           }
      //         >
      //           <TemplateInfo />
      //         </Suspense>
      //       ),
      //     },
      //   ],
      // },
      {
        path: '*',
        element: hasJwt ? (
          <NavAfterLogin>
            <PageNotFound />
          </NavAfterLogin>
        ) : (
          <PageNotFound />
        ),
      },
    ],
  },
]);

(window as any).rrNavigate = computeRouter.navigate;

export default computeRouter;
