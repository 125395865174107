import React from 'react';
import { Button } from '@spheron/ui-library';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PageNotFoundIcon } from '../../../../assets/compute/backgrounds/404.svg';
import GlobalStyle from '../../../../styles/compute/global.module.scss';

function PageNotFound() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('jwt-token');
  return (
    <div
      className={`${GlobalStyle.page__not__found} h-full w-full flex flex-col items-center 
      justify-center`}
    >
      <PageNotFoundIcon />
      <div className={`${GlobalStyle.page__not__found__sub}`}>
        <p>
          Sorry the page you requested could not be found <br /> Please login to
          continue{' '}
        </p>
        <Button
          buttonType="primary"
          label={isLoggedIn ? 'Back to Home' : 'Login'}
          size="medium"
          onClick={() =>
            navigate(isLoggedIn ? '/choose-spheron-app' : '/login')
          }
        />
      </div>
    </div>
  );
}

export default PageNotFound;
